import * as actionTypes from '../actions/ActionTypes'
import { all, call, select, take } from 'redux-saga/effects'


function* allRefReceived() {
    const { collector } = yield select()
    const { i18n, location } = collector

    if (i18n === null || location === null) {
        console.warn("Missing refs: i18n: ", i18n, " location: ", location)
        return;
    }


    console.debug("Refs: i18n: ", i18n, " location: ", location)

    const search = location.search;
    const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
    
    console.debug("URL parameters: ", urlParams);
    const lang = urlParams["lang"] ?? "en";
    if (lang === null || lang === "") {
        console.warn("There is no 'lang' parameter in the URL. We are going to use the default language: ", i18n.language)
        return;
    }

    yield call(i18n.changeLanguage, lang)
}

export function* saga() {
    while (true) {
        yield all([
            take(actionTypes.COLLECTOR_SET_I18N),
            take(actionTypes.COLLECTOR_SET_LOCATION)
        ]);
        console.debug("All refs received...")
        yield call(allRefReceived)
    }
}
