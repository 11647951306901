import { takeEvery, putResolve, call, select } from 'redux-saga/effects'

import * as validator from 'email-validator'

import * as actionTypes from '../actions/ActionTypes'
import * as actions from '../actions/Send'
import * as repo from '../../repository/Send'

import { OperationResult } from '../../model/OperationResult'

function* sendStart(action) {
    let result = null;
    const { payload } = action
    const { desiredEmail, desiredUser } = payload

    const isEmailValid = validator.validate(desiredEmail)
    const isUsernameValid = desiredUser != null && desiredUser.length >= 1
    
    let operationResult = null;

    if (!isEmailValid) {
        console.warn("Invalid email: ", desiredEmail)
        operationResult =  OperationResult.validationError("send.invalid_email")
        yield putResolve(actions.setResult(operationResult))
    }

    if (!isUsernameValid) {
        console.warn("Invalid username: ", desiredUser)
        operationResult = OperationResult.validationError("send.invalid_username")
        yield putResolve(actions.setResult(operationResult))
    }

    if (operationResult == null) {
        try {
            const { collector} = yield select()
            const { i18n } = collector
            const lang = i18n.language === "enUS" ? "en" : i18n.language ?? "en"

            console.debug("Sending password reset request for user: ", desiredUser, " and email: ", desiredEmail, " with language: ", lang)

            const params = {
                lang: lang,
                desiredEmail: desiredEmail,
                desiredUser: desiredUser,
            }

            result = yield call(repo.send, params) //NODE: call is blocking!
            console.debug("Password reset request sent successfully: ", result)

            operationResult = OperationResult.fromAxiosSuccess(result)
            operationResult.messageKey = "send.success"
       
        } catch (exp) {
            
            console.warn("Error while sending password reset request: ", exp);
            operationResult = OperationResult.fromAxiosError(exp)
        }
    } else {
        console.debug("Password reset request not sent, because of invalid username or email.")
    }

    console.debug("Password reset request finished.")
    yield putResolve(actions.setResult(operationResult))
    yield putResolve(actions.finished())
}

export function* saga() {
    yield takeEvery(actionTypes.SEND_START, sendStart)
}
