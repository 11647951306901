import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../store/actions/Change'


import { useTranslation } from 'react-i18next';
import { useLocation,  } from 'react-router-dom';
import { Button, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Logo from './LogoComp';
import Result from './ResultComp';

const useStyles = makeStyles(theme => ({
    avatar: {
        "& *": {
            fontSize: "7rem"
        }
    },
    mainContainer: {
        paddingTop: "2rem",
        paddingBottom: "2rem",
    },
    usernameContainer: {
        marginTop: ".75rem",
        marginBottom: "1.25rem",
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        textAlign: "center",
        color: "222222",
        fontSize: "1.15rem",
        "& *": {
            borderRadius: ".5rem",
            padding: ".5rem",
            backgroundColor: "#dddddd",
        }
    },
    child: {
        marginTop: "2rem",
    },
    submitContainer: {
        paddingBottom: "1rem",
    },
}));

const Inputs = (props) => {

    const { t } = useTranslation();
    const [ verifyPassword, setVerifyPassword ] = props.verifyState;
    const [ password, setPassword ] = props.passwordState;

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
                <TextField
                autoFocus={true}
                id="password"
                label={t("change.password")}
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value) }
                fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                id="verifyPassword"
                label={t("change.verify_password")}
                type="password"
                value={verifyPassword}
                onChange={(event) => setVerifyPassword(event.target.value) }
                fullWidth />
            </Grid>
        </Grid>
    )
}

const Change = (props) => {
     
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const passwordState = useState('');
    const verifyState = useState('');

    const { search } = location;
    const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
    console.debug("ChangePassword has been started with URL parameters: ", urlParams); 

    const { state } = props;
    const { change } = state;
    const { inProgress, result } = change;

    console.debug("Change result is: ", result)

    const handleSubmit = () => {
        
        const [ password ] = passwordState;
        const [ verifyPassword ] = verifyState;

        console.debug("Change password. password: " + password + ", verify password: " + verifyPassword)
        
        const authUsername = urlParams.user;
        const authPassword = urlParams.hash;
        console.debug("Change password. auth username: " + authUsername + ", auth password: " + authPassword)
        
        const credentials = {
            username: authUsername,
            password: authPassword,
        }

        const passwords = {
            password: password,
            verifyPassword: verifyPassword,
        }

        props.start(credentials, passwords);
    }
    const handleResultClose = () => props.setResult(null);

    const resultOrInputs = !result ? <Inputs verifyState={verifyState} passwordState={passwordState} /> : <Result result={result} onClose={handleResultClose} />
    const child = inProgress ? <LinearProgress /> : resultOrInputs

    return (
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} container justifyContent='center' alignItems='center' direction="column">
                <Logo />
                <h2>{t("change.title")}</h2>
            </Grid>
            <Grid item xs={12} className={classes.mainContainer}>
                <Typography>
                    {t("change.subtitle")}
                </Typography>
                <div variant='h6' className={classes.usernameContainer}>
                    <span>{urlParams.user}</span>
                </div>
                {child}
            </Grid>
            <Grid item xs={12} container justifyContent='end' alignItems='center' className={classes.submitContainer}>
                    <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="outlined">
                        {t("change.change_password")}
                    </Button>
             </Grid>
        </Grid>
    );
}

const mapDispatchToProps = dispatch => {
    return {
      start: (credentials, passwords) => dispatch(actions.start(credentials, passwords)),
      setResult: (value) => dispatch(actions.setResult(value)),
    }
  }
  
  function mapStateToProps(state) {
    return {
      state: state,
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Change)