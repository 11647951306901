import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import initStore from './store/Store';
import rootSaga from './store/sagas/Root';
import { Provider }  from 'react-redux'; //https://github.com/reduxjs/react-redux/issues/652
import { storeCreated } from './store/actions/Root';
import { BrowserRouter } from 'react-router-dom';

//Initializing the store
const { store, saga } = initStore()
saga.run(rootSaga)
store.dispatch(storeCreated())
console.debug("Store has been initialized.")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
