// Action Types import
import * as actionTypes from '../actions/ActionTypes'

// Other imports
import { update } from '../../other/Common.js'

const initialState = {
    inProgress: false,
    result: null,   
}

const send = (state = initialState, action) => {

    switch (action.type) {

        //password reset
        case actionTypes.SEND_START: return update(state, {inProgress: true, result: null})
        case actionTypes.SEND_FINISHED: return update(state, {inProgress: false})
        case actionTypes.SEND_SET_RESULT: return update(state, {result: action.payload})

        default: return state
    }
}

export default send