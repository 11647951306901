import { takeEvery, putResolve, call } from 'redux-saga/effects'

import * as actionTypes from '../actions/ActionTypes'
import * as actions from '../actions/Change'
import * as repo from '../../repository/Change'

import { OperationResult } from '../../model/OperationResult'
import { passwordBlacklistChars as invalidChars } from '../../other/PasswordBacklistChars'

function* changeStart(action) {
    const { payload } = action
    const { passwords } = payload
    const { password, verifyPassword } = passwords

    let operationResult = null;
    
    //checking if the passwords are valid
    if (password === null || password === "" || password !== verifyPassword) {
        operationResult = OperationResult.validationError("change.password_mismatch")
    }

    //checking if the password contains any invalid characters from the list
    for (let i = 0; i < invalidChars.length; i++) {
        const invalidChar = invalidChars[i]
        if (password.includes(invalidChar)) {
            operationResult = OperationResult.validationError("change.password_invalid_chars", { invalidChars: invalidChars })
            console.warn(`The password contains invalid character: ${invalidChar}`)
            break
        }
    }


    if (operationResult == null) {
        try {
            const { credentials } = payload

            const params = {
                credentials: credentials,
                newPassword: password,
            }

            const result = yield call(repo.change, params) //NODE: call is blocking!
            console.debug("Password chnage request sent successfully: ", result)

            operationResult = OperationResult.fromAxiosSuccess(result)
            operationResult.messageKey = "change.success"

        } catch (exp) {
            console.warn("Error while sending password change request: ", exp);
            operationResult = OperationResult.fromAxiosError(exp)
        }
    } else {
        console.debug("Password change request not sent, because the passwords validation failed.")
    }

    console.debug("Change password request finished. Operation result: ", operationResult)
    yield putResolve(actions.setResult(operationResult))
    yield putResolve(actions.finished())
}


export function* saga() {
    yield takeEvery(actionTypes.CHANGE_START, changeStart)
}
