import * as actionTypes from './ActionTypes'

//Send password reset
export const start = (username, email) => {
    return {
        type: actionTypes.SEND_START,
        payload: {
            desiredUser: username,
            desiredEmail: email,
        }
    }
}

export const finished = () => {
    return {
        type: actionTypes.SEND_FINISHED,
    }
}

export const setResult = (value) => {
    return {
        type: actionTypes.SEND_SET_RESULT,
        payload: value,
    }
}