export class OperationResult {
    constructor(
        statusCode,
        message,
        localizedMessage,
        messageKey,
    ) {
        this.statusCode = statusCode;
        this.message = message;
        this.localizedMessage = localizedMessage;
        this.messageKey = messageKey;
    }

    static fromAxiosError(axiosError) {
        const { response } = axiosError
        const { data, status } = response
        const { message, localizedMessage } = data
        return new OperationResult(status, message, localizedMessage);
    }

    static fromAxiosSuccess(axiosResponse) {
        const { status, statusText } = axiosResponse
        return new OperationResult(status, statusText);
    }

    static validationError(message, params) {
        //We use message as messageKey, because we want to display it by using i18n
        const res = new OperationResult(422, null, null, message);
        //Params are used for i18n interpolation
        res.params = params;
        return res;
    }

    isSuccess() {
        return this.statusCode.toString()[0] === '2';
    }
}