import * as actionTypes from './ActionTypes'

//Change password
export const start = (credentials, passwords) => {
    return {
        type: actionTypes.CHANGE_START,
        payload: {
            credentials: credentials,
            passwords: passwords,
        }
    }
}

export const finished = () => {
    return {
        type: actionTypes.CHANGE_FINISHED,
    }
}

export const setResult = (value) => {
    return {
        type: actionTypes.CHANGE_SET_RESULT,
        payload: value,
    }
}