import { Avatar } from '@material-ui/core';
import { makeStyles } from '@mui/styles';

const logoURL = `${process.env.PUBLIC_URL}/logo.png`

const useStyles = makeStyles(theme => ({
    avatar: {
    },
}));

const Logo = () => {
    const classes = useStyles();
    console.debug(`Logo URL is ${logoURL}`)
    return (
        <Avatar
            className={classes.avatar}
            sx={{ height: '64px', width: '64px', background: "white" }}
            src={logoURL}>
        </Avatar>
    );
}

export default Logo;