// Redux
import { combineReducers } from 'redux'

// Reducer imports
import send from './Send'
import change from './Change'
import collector from './Collector'


// Combine reducers
const rootReducer = combineReducers({
    send: send,
    change: change,
    collector: collector,
})

export default rootReducer