import React, {  useState } from 'react'
import { connect } from 'react-redux'

import * as actions from '../store/actions/Send'

import { 
    Grid,
    Button,
    Typography,
    TextField,
    LinearProgress,
    Link,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import Logo from './LogoComp';
import Result from './ResultComp';

const useStyles = makeStyles(theme => ({
    avatar: {
        "& *": {
            fontSize: "7rem"
        }
    },
    mainContainer: {
        paddingTop: "2rem",
        paddingBottom: "2rem",
    },
    child: {
        marginTop: "2rem",
    },
    didNotGet: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
    },
}));

const Inputs = ({emailState, usernameState}) => {
    
    const { t } = useTranslation()
    const [ email, setEmail ] = emailState
    const [ username, setUsername ] = usernameState

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
                <TextField
                autoFocus={true}
                id="username"
                label={t("send.username")}
                type="username"
                value={username}
                onChange={(event) => setUsername(event.target.value) }
                fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                id="email"
                label={t("send.email")}
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value) }
                fullWidth />
            </Grid>
        </Grid>
    )
}

const Send = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const emailState = useState('')
    const [ email ] = emailState
    const usernameState = useState('')
    const [ username ] = usernameState
    const { state } = props;
    const { send } = state;
    const { inProgress, result } = send;
    const ITRACK_CONTACT_URL = process.env.REACT_APP_ITRACK_CONTACT_URL;

    const handleSendPasswordReset = (username, email) => props.start(username, email);
    const handleResultClose = () => props.setResult(null);
    const handleDidNotGet = () => window.location.replace(ITRACK_CONTACT_URL);

    const resultOrInputs = result ? <Result result={result} onClose={handleResultClose} /> : <Inputs result={result} emailState={emailState} usernameState={usernameState} />
    const child = inProgress ? <LinearProgress /> : resultOrInputs
    
    const sendButtonKey = result ? "send.send_new_password_again" : "send.send_new_password";

    return (
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} container justifyContent='center' alignItems='center' direction="column">
                <Logo />
                <h2>{t("send.title")}</h2>
            </Grid>
            <Grid item xs={12} className={classes.mainContainer}>
                <Typography>
                    {t("send.subtitle")}
                </Typography>
                <Typography variant="subtitle2">
                    {t("send.text")}
                </Typography>
                <div className={classes.child}>
                    {child}
                </div>
            </Grid>
            <Grid item xs={12} container justifyContent='end' alignItems='center'>
                <Button
                    onClick={() => handleSendPasswordReset(username, email)}
                    color="primary"
                    variant="outlined">
                        {t(sendButtonKey)}
                </Button>
            </Grid>
            <Grid item xs={12} container justifyContent='start' alignItems='center' className={classes.didNotGet}>
                <Link
                to="#"
                href="#0"
                underline="none"
                onClick={handleDidNotGet} >{t("send.did_not_get_email")}</Link>
            </Grid>
        </Grid>
    );
}

const mapDispatchToProps = dispatch => {
    return {
      start: (username, email) => dispatch(actions.start(username, email)),
      setResult: (value) => dispatch(actions.setResult(value)),
    }
  }
  
  function mapStateToProps(state) {
    return {
      state: state,
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Send)