import * as actionTypes from './ActionTypes'

export const setI18n = (i18n) => {
    return {
        type: actionTypes.COLLECTOR_SET_I18N,
        payload: i18n,
    }
}

export const setLocation = (location) => {
    return {
        type: actionTypes.COLLECTOR_SET_LOCATION,
        payload: location,
    }
}
