import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

//https://m.itrack.hu/ws-rest/api/v1.1/passwordreset/changepassword?newPassword=xxxxxxxxxxxxx
export const change = (params) => {
    
    // Please uncomment the following line just for debugging purposes!
    //console.debug("Params in 'change' api call: ", params)
    
    return axios.post(
        `${BASE_URL}/passwordreset/changepassword`,
        {},
        {
            auth: params.credentials,
            params: {
                newPassword: params.newPassword,
            },
        }
    )
}